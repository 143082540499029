<template>
  <div>
    <v-dialog v-model="show_dialog" width="1100" persistent>
      <div class="neumorphism-pressed">
        <v-row class="pa-0 ma-0">
          <v-col :cols="details_cols" class="pa-0 ma-0">
            <div
              class="send_notification-title-section d-flex justify-space-between align-center py-4 px-8"
            >
              <div class="send_notification-title d-flex">
                Send Notification
              </div>
              <div>
                <button class="close-btn" @click="closeDialog()">
                  <v-icon class="close-icon">
                    mdi-window-close
                  </v-icon>
                </button>
              </div>
            </div>
            <div class="send_notification-wrapper">
              <v-stepper
                v-model="send_notification_stepper_id"
                class="send-notification-stepper-content"
              >
                <v-stepper-content
                  step="1"
                  class="pa-0 send-notification-stepper-content"
                >
                  <div class="send_notification-sub-title pl-2 pb-3">
                    Select Template for {{ notification_type }}
                    <div class="send-notification-bulk-warning">Note: For bulk notification, template must have a topic</div>
                  </div>
                  <div class="table-wrapper">
                    <v-data-table
                      v-model="selected_template"
                      class="neumorphism-pressed"
                      :headers="template_header"
                      :items="template_list"
                      :single-select="single_select"
                      :item-key="items.id"
                      :show-select="show_select"
                      :loading="loading"
                      :items-per-page="templates_per_page"
                    >
                      <template v-slot:loading>
                        <v-skeleton-loader
                          type="table-tbody"
                        ></v-skeleton-loader>
                      </template>
                      <template v-slot:[`item.body`]="{ item }">
                        <v-tooltip bottom max-width="500">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              class="body-text-wrapper"
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ item.body }}
                            </div>
                          </template>
                          <div>{{ item.body }}</div>
                        </v-tooltip>
                      </template>
                      <template v-slot:[`item.image`]="{ item }">
                        <div class="template-image pa-3">
                          <img :src="item.image" />
                        </div>
                      </template>
                    </v-data-table>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-0">
                  <v-form ref="send_notification_form">
                    <v-row no-gutters>
                      <v-col cols="4" class="pr-2">
                        <div class="mb-6">
                          <div class="send_notification-field-title">
                            Notification Type
                          </div>
                          <div class="notification-type-wrapper">
                            <v-select
                              outlined
                              :rules="$rules.REQURIED_FILED"
                              dense
                              v-model="notification_type"
                              hide-details
                              :items="notification_type_list"
                              item-text="text"
                            ></v-select>
                          </div>
                        </div>
                      </v-col>

                      <v-col
                        cols="4"
                        class="pr-2"
                        v-if="notification_type == 'individual'"
                      >
                        <div class="mb-6">
                          <div class="send_notification-field-title">
                            Choose filter
                          </div>
                          <div class="">
                            <v-select
                              dense
                              outlined
                              placeholder="Select filter"
                              hide-details
                              :items="filter_list"
                              v-model="filter"
                              :disabled="customer_loading"
                            ></v-select>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="4"
                        class="pr-2"
                        v-if="
                          notification_type == 'individual' &&
                            filter == 'Loan Status'
                        "
                      >
                        <div class="mb-6">
                          <div class="send_notification-field-title">
                            Customer loan status
                          </div>
                          <div class="">
                            <v-select
                              clearable
                              dense
                              outlined
                              placeholder="Select customer loan status"
                              :disabled="customer_loading"
                              hide-details
                              :items="loan_status_list"
                              v-model="customer_loan_status"
                              @change="getUserList"
                              :rules="$rules.REQURIED_FILED"
                            ></v-select>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="4"
                        class="pr-2"
                        v-if="
                          notification_type == 'individual' &&
                            filter == 'DPD Range'
                        "
                      >
                        <div class="mb-6">
                          <div class="send_notification-field-title">
                            DPD range
                          </div>
                          <div class="">
                            <v-select
                              clearable
                              dense
                              outlined
                              placeholder="Select DPD range"
                              hide-details
                              :items="dpd_range_list"
                              :disabled="customer_loading"
                              v-model="dpd_range"
                              @change="getUserList"
                            ></v-select>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="4"
                        class="pr-2"
                        v-if="
                          notification_type == 'individual' &&
                            filter == 'Profile Status'
                        "
                      >
                        <div class="mb-6">
                          <div class="send_notification-field-title">
                            Customer Profile Status
                          </div>
                          <div class="">
                            <v-select
                              dense
                              outlined
                              placeholder="Select customer profile status"
                              hide-details
                              :items="customer_profile_status_list"
                              v-model="customer_profile_status"
                              @change="getUserList"
                              :disabled="customer_loading"
                            ></v-select>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div
                          v-if="notification_type == 'individual'"
                          class="neumorphism-pressed pa-6 customer-table-wrapper"
                        >
                          <v-row>
                            <v-col cols="4">
                              <v-text-field
                                v-model="customer_search"
                                append-icon="mdi-magnify"
                                label="Search"
                                outlined
                                dense
                                hide-details
                                :disabled="customer_loading"
                                v-on:keyup.enter="getUserList()"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-data-table
                                class="neumorphism-flat"
                                v-model="selected_template"
                                :headers="customer_header"
                                :items="customer_list"
                                hide-default-footer
                                :loading="customer_loading"
                                :items-per-page="total_customer_page_count"
                              >
                                <template v-slot:loading>
                                  <v-skeleton-loader
                                    type="table-tbody"
                                  ></v-skeleton-loader>
                                </template>
                              </v-data-table>
                            </v-col>
                            <v-col cols="12">
                              <div
                                class="mt-4"
                                v-if="notification_type == 'individual'"
                              >
                                <v-pagination
                                  class="custom"
                                  v-model="page_number"
                                  :length="total_page_count"
                                  :total-visible="7"
                                  light
                                  circle
                                  @input="getUserList"
                                ></v-pagination>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <!-- <v-col cols="12" class="d-flex justify-end my-5 pr-4">
                    <v-btn
                      @click="sendNotification()"
                      elevation="0"
                      class="send-btn"
                      rounded
                      :loading="btn_loader"
                    >
                      Send Notification
                    </v-btn>
                  </v-col> -->
                    </v-row>
                  </v-form>
                </v-stepper-content>
              </v-stepper>

              <!-- <v-stepper
            v-model="send_notification_stepper_id"
            class="send-notification-stepper-content"
          >
            <v-stepper-content
              step="1"
              class="pa-0 send-notification-stepper-content"
            >
              <div class="send_notification-sub-title pl-2 pb-3">
                Select Template
              </div>
              <v-data-table
                v-model="selected_template"
                class="neumorphism-pressed"
                :headers="template_header"
                :items="template_list"
                :single-select="single_select"
                :item-key="items.id"
                :show-select="show_select"
                :loading="loading"
                :items-per-page="templates_per_page"
              >
                <template v-slot:loading>
                  <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
                </template>
                <template v-slot:[`item.body`]="{ item }">
                  <v-tooltip bottom max-width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="body-text-wrapper" v-bind="attrs" v-on="on">
                        {{ item.body }}
                      </div>
                    </template>
                    <div>{{ item.body }}</div>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.image`]="{ item }">
                  <div class="template-image pa-3">
                    <img :src="item.image" />
                  </div>
                </template>
              </v-data-table>
              <div class="d-flex align-center justify-end mt-6 mb-3 mr-4">
                <button class="show-preview-btn mr-6" @click="togglePreview()">
                  <div class="show-preview-icon">
                    Show Preview
                  </div>
                </button>
                <button class="select-btn" @click="selectTemplate()">
                  <div class="select-icon">
                    Select
                  </div>
                </button>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
              <v-form ref="send_notification_form">
                <v-row no-gutters>
                  <v-col cols="4" class="pr-2">
                    <div class="mb-6">
                      <div class="send_notification-field-title">
                        Notification Type
                      </div>
                      <div class="notification-type-wrapper">
                        <v-select
                          outlined
                          :rules="$rules.REQURIED_FILED"
                          dense
                          v-model="notification_type"
                          hide-details
                          :items="notification_type_list"
                          item-text="text"
                        ></v-select>
                      </div>
                    </div>
                  </v-col>

                  <v-col
                    cols="4"
                    class="pr-2"
                    v-if="notification_type == 'individual'"
                  >
                    <div class="mb-6">
                      <div class="send_notification-field-title">
                        Choose filter
                      </div>
                      <div class="">
                        <v-select
                          dense
                          outlined
                          placeholder="Select filter"
                          hide-details
                          :items="filter_list"
                          v-model="filter"
                          :disabled="customer_loading"
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    class="pr-2"
                    v-if="
                      notification_type == 'individual' &&
                        filter == 'Loan Status'
                    "
                  >
                    <div class="mb-6">
                      <div class="send_notification-field-title">
                        Customer loan status
                      </div>
                      <div class="">
                        <v-select
                          clearable
                          dense
                          outlined
                          placeholder="Select customer loan status"
                          :disabled="customer_loading"
                          hide-details
                          :items="loan_status_list"
                          v-model="customer_loan_status"
                          @change="getUserList"
                          :rules="$rules.REQURIED_FILED"
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    class="pr-2"
                    v-if="
                      notification_type == 'individual' && filter == 'DPD Range'
                    "
                  >
                    <div class="mb-6">
                      <div class="send_notification-field-title">
                        DPD range
                      </div>
                      <div class="">
                        <v-select
                          clearable
                          dense
                          outlined
                          placeholder="Select DPD range"
                          hide-details
                          :items="dpd_range_list"
                          :disabled="customer_loading"
                          v-model="dpd_range"
                          @change="getUserList"
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    class="pr-2"
                    v-if="
                      notification_type == 'individual' &&
                        filter == 'Profile Status'
                    "
                  >
                    <div class="mb-6">
                      <div class="send_notification-field-title">
                        Customer Profile Status
                      </div>
                      <div class="">
                        <v-select
                          dense
                          outlined
                          placeholder="Select customer profile status"
                          hide-details
                          :items="customer_profile_status_list"
                          v-model="customer_profile_status"
                          @change="getUserList"
                          :disabled="customer_loading"
                        ></v-select>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div
                      v-if="notification_type == 'individual'"
                      class="neumorphism-pressed pa-6 customer-table-wrapper"
                    >
                      <v-row>
                        <v-col cols="4">
                          <v-text-field
                            v-model="customer_search"
                            append-icon="mdi-magnify"
                            label="Search"
                            outlined
                            dense
                            hide-details
                            :disabled="customer_loading"
                            v-on:keyup.enter="getUserList()"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            class="neumorphism-flat"
                            v-model="selected_template"
                            :headers="customer_header"
                            :items="customer_list"
                            hide-default-footer
                            :loading="customer_loading"
                            :items-per-page="total_customer_page_count"
                          >
                            <template v-slot:loading>
                              <v-skeleton-loader
                                type="table-tbody"
                              ></v-skeleton-loader>
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="mt-4"
                            v-if="notification_type == 'individual'"
                          >
                            <v-pagination
                              class="custom"
                              v-model="page_number"
                              :length="total_page_count"
                              :total-visible="7"
                              light
                              circle
                              @input="getUserList"
                            ></v-pagination>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end my-5 pr-4">
                    <v-btn
                      @click="sendNotification()"
                      elevation="0"
                      class="send-btn"
                      rounded
                      :loading="btn_loader"
                    >
                      Send Notification
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-stepper-content>
          </v-stepper> -->
            </div>
            <div
              class="btns-wrapper d-flex align-center justify-end pt-5 pb-6 pr-4"
            >
              <div class="test-wrapper" v-if="selected_template[0]">
                <!-- v-if="show_mobile_test" -->
                <input
                  placeholder="Enter mobile no."
                  :readonly="test_loading"
                  :disabled="test_loading"
                  v-model="test_mobile"
                  class="test-mobile-field neumorphism-pressed"
                  :class="show_mobile_test_class"
                  transition="slide-x-transition"
                  type="text"
                />
                <button
                  :disabled="test_loading"
                  v-if="show_mobile_test"
                  class="test-clear-btn"
                  @click="closeTest()"
                >
                  <v-icon class="close-icon">
                    mdi-window-close
                  </v-icon>
                </button>
                <button
                  v-if="!show_mobile_test"
                  class="test-btn show-preview-btn"
                  @click="show_mobile_test = !show_mobile_test"
                >
                  <div class="show-preview-icon">
                    Test
                  </div>
                </button>
                <button
                  :readonly="test_loading"
                  :disabled="test_loading"
                  @click="testNotification()"
                  v-else
                  class="test-btn show-preview-btn"
                >
                  <div class="show-preview-icon">
                    Send
                  </div>
                </button>
              </div>
              <button
                v-if="send_notification_stepper_id != 2 && selected_template[0]"
                class="show-preview-btn mr-6"
                @click="togglePreview()"
              >
                <div class="show-preview-icon">
                  <span v-if="show_preview">
                    Hide
                  </span>
                  <span v-else>
                    Show
                  </span>
                  Preview
                </div>
              </button>
              <button
                v-if="
                  notification_type == 'individual' &&
                    send_notification_stepper_id == 1
                "
                class="select-btn"
                @click="selectTemplate()"
              >
                <div class="select-icon">
                  Select
                </div>
              </button>
              <v-btn
                v-if="
                  notification_type == 'bulk' ||
                    send_notification_stepper_id == 2
                "
                @click="sendNotification()"
                elevation="0"
                class="send-btn"
                rounded
                :loading="btn_loader"
                :disabled="btn_loader"
              >
                Send Notification
              </v-btn>
            </div>
          </v-col>
          <v-col v-if="show_preview" class="pa-0 ma-0 d-flex align-center">
            <NotificationPreview ref="notification_preview" />
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>
<script>
export default {
  components: {
    NotificationPreview: () => import("./PreviewNotification.vue"),
  },
  data() {
    return {
      show_dialog: false,
      details_cols: 12,
      show_preview: false,
      btn_loader: false,
      templates_per_page: 10,
      send_notification_stepper_id: 1,
      loading: false,
      customer_loading: false,
      page_number: 1,
      total_page_count: 2,
      total_customer_page_count: 10,
      show_mobile_test: false,
      test_mobile: null,
      test_loading: false,

      topic_loading: false,
      single_select: true,
      template_list: [],
      customer_list: [],
      selected_template: [],
      customer_id: null,
      notification_type: "",
      notification_type_list: [
        { text: "Bulk", value: "bulk" },
        { text: "Individual", value: "individual" },
      ],
      filter: "Loan Status",
      filter_list: ["DPD Range", "Loan Status", "Profile Status"],
      template_header: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          // class: "primary_2 white--text",
        },
        {
          text: "Created On",
          align: "start",
          sortable: false,
          value: "created",
          // class: "primary_2 white--text",
        },
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "title",
          // class: "primary_2 white--text",
        },
        {
          text: "Image",
          align: "start",
          sortable: false,
          value: "image",
          // class: "primary_2 white--text",
        },
        {
          text: "Topic",
          align: "start",
          sortable: false,
          value: "topic",
          // class: "primary_2 white--text",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type",
          // class: "primary_2 white--text",
        },
        {
          text: "Body",
          align: "start",
          sortable: false,
          value: "body",
          // class: "primary_2 white--text",
        },
        {
          text: "URL",
          align: "start",
          sortable: false,
          value: "url",
          width: 50,
          // class: "primary_2 white--text",
        },
      ],
      customer_header: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "custom_user_id",
          // class: "primary_2 white--text",
        },
        {
          text: "Customer Name",
          align: "start",
          sortable: false,
          value: "full_name",
          // class: "primary_2 white--text",
        },
        {
          text: "Mobile",
          align: "start",
          sortable: false,
          value: "mobile",
          // class: "primary_2 white--text",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          // class: "primary_2 white--text",
        },
      ],
      customer_search: "",
      customer_profile_status: "",
      customer_profile_status_list: [
        "Check Eligibility Stage1",
        "Check Eligibility Stage2",
        "Employment Details",
        "General Info",
        "Address Details",
        "Rent Address Proof",
        "KYC",
        "Bank Statement",
        "Profile Completed",
      ],
      customer_loan_status: "",
      loan_status_list: [
        "Applied",
        "Verification Pending",
        "Approved",
        "Disbursed",
        "Rejected",
        "Completed",
      ],
      dpd_range: "",
      dpd_range_list: [
        "0 - 30",
        "31 - 60",
        "61 - 90",
        "91 - 180",
        "181 - 360",
        "361+",
      ],
    };
  },
  computed: {
    headers() {
      if (this.notification_type == "individual") {
        return this.customer_header;
      }
      if (this.notification_type == "bulk") {
        return this.template_header;
      }
      return [];
    },
    items() {
      // alert(this.notification_type)
      if (this.notification_type == "individual") {
        return this.customer_list;
      } else if (this.notification_type == "bulk") {
        return this.template_list;
      } else {
        return [];
      }
    },
    show_select() {
      // if (this.notification_type == "individual") {
      //   return false;
      // }
      // if (this.notification_type == "bulk") {
      //   return true;
      // }
      return true;
    },
    show_mobile_test_class() {
      if (this.show_mobile_test) {
        return "show-text-mobile-field";
      } else {
        return "";
      }
    },
  },
  watch: {
    // notification_type(new_value) {
    //   if (new_value == "individual") this.getUserList();
    // },
    filter() {
      this.dpd_range = "";
      this.customer_loan_status = "";
      this.customer_profile_status = "";
    },
    // customer_search(new_value) {
    //   this.getUserList();
    // },
    show_dialog(new_value) {
      if (!new_value) {
        this.send_notification_stepper_id = 1;
        // this.$refs.send_notification_form.reset();
        this.single_select = true;
        this.selected_template = [];
      }
    },
    send_notification_stepper_id(new_value) {
      if (new_value == 2) {
        this.getUserList();
        this.details_cols = 12;
        this.show_preview = false;
      }
    },
    selected_template(new_value) {
      // console.log("selected_template", new_value[0]);
      if (new_value[0]) {
        // console.log(new_value[0]);
        this.$store.dispatch("notification/setPreviewDetails", {
          title: new_value[0].title,
          body: new_value[0].body,
          image: new_value[0].image,
        });
      } else {
        this.details_cols = 12;
        this.show_preview = false;
      }
    },
  },
  methods: {
    async openSendNotificationDialog(value) {
      this.show_dialog = true;
      this.notification_type = value;
      // this.send_notification_stepper_id = 2;
      await this.getTemplateList();
      // await this.getUserList();
    },
    selectTemplate() {
      if (this.selected_template[0]?.id) {
        this.show_mobile_test = false;
        this.test_mobile = "";
        this.send_notification_stepper_id = 2;
      } else {
        this.showSnakeBar("error", "Please select template");
      }
    },
    closeDialog() {
      this.show_dialog = false;
      this.show_mobile_test = false;
      this.test_mobile = "";
    },
    closeTest() {
      this.test_mobile = "";
      this.show_mobile_test = false;
      this.test_mobile = "";
    },
    testNotification() {
      let v = this.test_mobile;
      if (!isNaN(parseFloat(v)) && v >= 5000000000 && v <= 9999999999) {
        const self = this;
        self.test_loading = true;

        let formData = {};
        formData["notification_id"] = self.selected_template[0].id;
        formData["mobile"] = self.test_mobile;

        const successHandler = () => {
          this.showSnakeBar("success", "Notification sent");
          this.test_mobile = "";
          this.show_mobile_test = false;
          self.test_loading = false;
        };
        const finallyHandler = () => {
          self.test_loading = false;
        };
        self.POST_request(
          self,
          self.$urls.TEST_NOTIFICATION,
          formData,
          successHandler,
          null,
          null,
          finallyHandler
        );
      } else {
        this.showSnakeBar("error", "Please enter valid mobile number");
      }
    },
    togglePreview() {
      if (this.show_preview == true) {
        this.details_cols = 12;
        this.show_preview = false;
      } else {
        this.details_cols = 8;
        this.show_preview = true;
      }
      // this.$refs.notification_preview.openNotificationPreview();
    },
    getTemplateList() {
      const self = this;
      self.loading = true;
      const lookup_key = "";
      self.template_list = [];
      // self.tabs_item = [];
      let params = {};
      const successHandler = (response) => {
        // console.log("template list", response.data.result);
        self.template_list = response.data.result;

        self.loading = false;
      };
      const failureHandler = () => {
        self.loading = false;
      };
      const finallyHandler = () => {};
      self.GET_request(
        self,
        self.$urls.FCM_NOTIFICATION,
        lookup_key,
        params,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
    getUserList() {
      const self = this;
      self.customer_loading = true;
      // const lookup_key = "";
      self.customer_list = [];
      // self.tabs_item = [];
      let body = {
        page_number: this.page_number,
        page_length: 10,
        search_query: this.customer_search,
        profile_status: this.customer_profile_status,
        loan_status: this.customer_loan_status,
        dpd_range: this.dpd_range,
      };
      const successHandler = (response) => {
        console.log("user list", response.data.result);
        self.customer_list = response.data.result.customer_list;
        self.total_page_count = response.data.result.total_page_count;
        self.customer_loading = false;
      };
      const failureHandler = () => {
        self.customer_loading = false;
      };
      const finallyHandler = () => {};
      self.POST_request(
        self,
        self.$urls.GET_USER_LIST,
        body,
        successHandler,
        null,
        failureHandler,
        finallyHandler
      );
    },
    sendNotification() {
      if (this.selected_template[0]?.id) {
        const self = this;
        self.btn_loader = true;

        let formData = {};
        formData["notification_id"] = self.selected_template[0].id;
        if (self.notification_type == "individual") {
          if (self.customer_profile_status)
            formData["profile_status"] = self.customer_profile_status;
          else if (self.customer_loan_status)
            formData["loan_status"] = self.customer_loan_status;
          else if (self.dpd_range) formData["dpd_range"] = self.dpd_range;
          if (self.customer_search)
            formData["search_query"] = self.customer_search;
        }

        const successHandler = () => {
          self.$refs.send_notification_form.reset();
          self.single_select = true;
          self.btn_loader = false;
          self.show_dialog = false;
          this.$store.dispatch("notification/setRefreshApi");
        };
        const finallyHandler = () => {
          self.btn_loader = false;
        };
        if (self.notification_type == "individual") {
          self.request_POST(
            self,
            self.$urls.SEND_INDIVIDUALLY,
            formData,
            successHandler,
            null,
            null,
            finallyHandler
          );
        } else {
          self.request_POST(
            self,
            self.$urls.SEND_NOTIFICATION_ON_TOPIC,
            formData,
            successHandler,
            null,
            null,
            finallyHandler
          );
        }
      } else {
        this.showSnakeBar("error", "Please select template");
      }
    },
  },
};
</script>
<style scoped>
.neumorphism-pressed {
  height: 925px;
}
.send_notification-title-section {
  border-bottom: 1px solid #ffa70257 !important;
}
.send_notification-title {
  font-size: 25px;
  font-weight: 900;
  /* color: #6d7073; */
  color: #616467;
}
.send_notification-sub-title {
  font-size: 20px;
  font-weight: 800;
  /* color: #6d7073; */
  color: #616467;
}
.close-btn {
  padding: 5px;
  border-radius: 50%;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.close-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: #ffd641;
  background-image: linear-gradient(90deg, #ffd641 0%, #ff003c 100%);

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.close-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.notification-type-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.notification-type {
  outline: none !important;
  border: none !important;
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
}
.send_notification-field-title {
  color: #616467;
  font-weight: 700;
  padding: 0 0 5px 5px;
}
.notification-type-arrow {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(40%);
}
.send_notification-template-field {
  outline: none !important;
  border: none !important;
  padding: 0 0 7px 20px !important;
  border-radius: 20px !important;
  background: #dae6f1 !important;
  box-shadow: inset 6px 6px 12px #c1c1c1, inset -6px -6px 12px #ffffff !important;
  border: 1px solid #a9d7ff57 !important;
}

.send-btn {
  padding: 0 20px;
  color: #fff;
  font-weight: 800;
  background: hsla(197, 100%, 63%, 1);

  background: linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  );

  background: -moz-linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  );

  background: -webkit-linear-gradient(
    45deg,
    rgb(255, 236, 29) 0%,
    rgb(252, 168, 13) 100%
  );

  /* box-shadow: 0px 0px 12px hsla(294, 100%, 55%, 1) !important; */

  /* box-shadow: 6px 6px 12px #b5b5b5, -6px -6px 12px #ffffff; */
  box-shadow: 6px 6px 12px #7e7e7ea9, -6px -6px 6px #ffffff !important;
  /* filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#40C9FF", endColorstr="#E81CFF", GradientType=1 ); */
}
.send-btn:hover {
  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.send-notification-stepper-content {
  border-radius: 20px;
  background: #e3edf7 !important;
  box-shadow: none;
  /* padding-bottom: 20px; */
}
.select-btn {
  padding: 7px 15px;
  border-radius: 20px;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.select-icon {
  /* font-size: 72px; */
  font-weight: 900;
  background-color: hsla(197, 100%, 63%, 1);
  background-image: linear-gradient(
    90deg,
    hsla(197, 100%, 63%, 1) 0%,
    hsla(294, 100%, 55%, 1) 100%
  );

  /* background-color: #ffe53b; */
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #ff2525 74%); */

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.select-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.show-preview-btn {
  padding: 7px 15px;
  border-radius: 20px;
  background: #eef5fb !important;
  border: 1px solid #a9d7ff57 !important;

  box-shadow: 6px 6px 12px #dbe1e7, -6px -6px 12px #ffffff !important;
}
.show-preview-icon {
  font-weight: 900;

  background-color: hsla(270, 94%, 25%, 1);

  background-image: linear-gradient(
    90deg,
    hsla(270, 94%, 25%, 1) 0%,
    hsla(158, 94%, 49%, 1) 100%
  );

  background-image: -moz-linear-gradient(
    90deg,
    hsla(270, 94%, 25%, 1) 0%,
    hsla(158, 94%, 49%, 1) 100%
  );

  background-image: -webkit-linear-gradient(
    90deg,
    hsla(270, 94%, 25%, 1) 0%,
    hsla(158, 94%, 49%, 1) 100%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.show-preview-btn:hover {
  background: #e3edf7;
  box-shadow: inset 6px 6px 12px #dbe1e7, inset -6px -6px 12px #ffffff !important;
}
.customer-table-wrapper {
  border-radius: 20px;
}
.template-image img {
  width: 150px;
}
.body-text-wrapper {
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.send_notification-wrapper {
  padding: 15px 30px 10px 30px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 500px;
}
.table-wrapper {
  /* height: 400px; */
  /* overflow: scroll; */
}
.btns-wrapper {
  border-top: 1px solid #ffa70257 !important;
  /* position: static; */
  /* bottom: 0; */
}
.test-wrapper {
  position: relative;
  margin-right: 20px;
  /* background: blue; */
  width: 290px;
  height: 50px;
  /* overflow-x: hidden; */
  border-radius: 20px;
}
.test-btn {
  position: absolute;
  top: 4px;
  right: 4px;
  /* top: 0px; */
  /* right: 0px; */
}
.test-clear-btn {
  position: absolute;
  top: 10px;
  right: 85px;
}
.test-mobile-field {
  width: 100%;
  border-radius: 20px;
  padding: 11px 15px;
  border-color: #8bbbe557 !important;
  /* opacity: 0; */
  /* transform: translateX(100%); */
  transform: scaleX(0);

  transition: transform 200ms ease-in;
  transform-origin: right;
}
.show-text-mobile-field {
  /* opacity: 1; */
  transform: scaleX(1);
  /* transform: translateX(0); */
}
.send-notification-bulk-warning{
  font-size: 13px;
  color: #f58634;
  font-weight: 600;
}
</style>
